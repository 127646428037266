@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', 'system-ui', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', 'system-ui', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-menu-item-selected {
  background-color: #0049ff !important;
}

.active-link {
  color: red !important;
}

/*  from https://codesandbox.io/s/z0ryv?file=/index.css:0-299 */
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.disabled-row {
  background-color: #f5f5f5;
}

.ant-btn-primary {
  background-color: #0049ff !important;
}

.ant-switch-checked {
  background: #0049ff !important ;
}

.rainbow-button {
  /* font-size: 100px; */
  position: relative;
  cursor: pointer;
  margin-left: 0.25rem;
}
.rainbow-button:before {
  content: '';
  position: absolute;
  height: 0.1em;
  width: 100%;
  color: transparent;
  background: linear-gradient(
    to right,
    #fd004c,
    #fe9000,
    #fff020,
    #3edf4b,
    #3363ff,
    #b102b7
  );
  background-size: 200% 100%;
  bottom: -0.05em;
  transition: 1.5s;
}
.rainbow-button:hover:before {
  background-position: 100% 0;
}
